    <mat-form-field class="form-field-full-width">
      <mat-label>Jobtitel</mat-label>
      <input [(ngModel)]="jobTitle" matInput placeholder="Job Titel">
    </mat-form-field>
    <br>
    <mat-form-field class="form-field-full-width">
      <mat-label>Jobbeschreibung</mat-label>
      <input [(ngModel)]="jobDescription" matInput placeholder="Das Gerät muss wegen mangel an XY repariert werden.">
    </mat-form-field>

  <button mat-fab color="primary" class="fixed-right-bottom-button" (click)="saveJob()">
    <mat-icon>done</mat-icon>
  </button>
  <button mat-fab color="accent" class="fixed-right-button" [routerLink]="['/jobs']">
    <mat-icon>clear</mat-icon>
  </button>
