import { Component, OnInit } from '@angular/core';
import {ILocationComponent} from '../../../../models/location-component';
import {Guid} from 'guid-typescript';
import {DataService} from '../../../../services/data-service/data.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  private id: string;
  private sub: any;
  public locationComponent: ILocationComponent;
  public googleMapsLink: string;
  constructor(private dataService: DataService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.locationComponent = this.dataService.getLocationComponentById(this.id);
    this. googleMapsLink = 'https://www.google.com/maps/search/' + this.locationComponent.place + '+' + this.locationComponent.zipCode + '+' + this.locationComponent.address;
  }

}
