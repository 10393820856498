import {Component, OnInit, Output} from '@angular/core';
import {JobComponent} from '../../../models/job-component-model';
import {IEventConsumer} from '../../../services/event-consumer/EventConsumer';
import {IEvent} from '../../../services/event-publisher/events/event';
import {AddJobEvent} from '../../../services/event-publisher/events/add-job-event';
import {EventPublisherService} from '../../../services/event-publisher/event-publisher.service';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data-service/data.service';
import {NavTitleService} from '../../../services/nav-title-service/nav-title.service';


@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, IEventConsumer {
  @Output() componentTitle = 'Aufträge';
  public jobComponents: JobComponent[];
  constructor(private dataService: DataService, private navTitleService: NavTitleService) {
    EventPublisherService.register(new AddJobEvent().eventName, this.consumeEvent);
    console.log(this.dataService);
  }

  ngOnInit(): void {
    this.jobComponents = this.dataService.getAllJobComponentsByCompanyId(Guid.create().toString());
    this.navTitleService.setNavTitle('Aufträge');
  }

  consumeEvent(event: IEvent): void {

    let addJobEvent = event as AddJobEvent;
    console.log(addJobEvent);
    //this.jobComponents.push({id: addJobEvent.jobId, title: addJobEvent.jobTitle, description: addJobEvent.jobDescription});

  }


trackByFn(index, item: JobComponent){
  return item.id;
}


}
