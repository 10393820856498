<app-nav-bar (toggleSidenav)=navigationSidenav.toggle()></app-nav-bar>
<mat-sidenav-container>
  <mat-sidenav #navigationSidenav mode="over" [fixedInViewport]="true">
    <mat-toolbar>
      <button mat-icon-button color="black" (click)="navigationSidenav.toggle()">
        <mat-icon>menu_open</mat-icon>
      </button>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="jobs"><mat-icon aria-hidden="false">folder_open</mat-icon> Aufträge</a>
      <a mat-list-item href="dailyReport"><mat-icon aria-hidden="false">ballot</mat-icon> Tagesbericht</a>
      <a mat-list-item href="templates"><mat-icon aria-hidden="false">square_foot</mat-icon> Templates</a>
      <a mat-list-item href="customers"><mat-icon aria-hidden="false">portrait</mat-icon> Kunden</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
