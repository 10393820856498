import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavTitleService {

  public navTitle = new BehaviorSubject<string>('WorkProcessIT');
  private navTitle$ = this.navTitle.asObservable();
  constructor() {
  }

  setNavTitle(title: string){
    this.navTitle.next(title);
  }

  getTitle(): Observable<string>{
    return this.navTitle$;
  }
}
