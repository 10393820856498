import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DataService } from 'src/app/services/data-service/data.service';
import { EventPublisherService } from 'src/app/services/event-publisher/event-publisher.service';
import { AddJobEvent } from 'src/app/services/event-publisher/events/add-job-event';
import {JobComponent} from 'src/app/models/job-component-model';



@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit {

  public jobTitle = '';
  public jobDescription = '';

  constructor(private dbService:NgxIndexedDBService<any>, private dataService: DataService) { }

  ngOnInit(): void {
  }

  saveJob(): void{
    var newJob = new AddJobEvent(this.jobTitle, this.jobDescription);
    this.jobTitle = '';
    this.jobDescription = '';
    EventPublisherService.publishEvent(newJob);
  }

}
