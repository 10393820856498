import { Component, OnInit } from '@angular/core';
import {NavTitleService} from '../../../services/nav-title-service/nav-title.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor( private navTitleService: NavTitleService) { }

  ngOnInit(): void {
    this.navTitleService.setNavTitle('Tagesbericht');
  }

}
