import { OnInit, Component, ViewContainerRef, ComponentFactory, AfterViewInit, ViewChild, ComponentFactoryResolver} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { IComponent } from 'src/app/models/component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TimeTrackingComponent } from '../documentationComponents/time-tracking/time-tracking.component';
import { LocationComponent } from '../documentationComponents/location/location.component';
import {NavTitleService} from '../../../services/nav-title-service/nav-title.service';
import {Guid} from 'guid-typescript';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements AfterViewInit, OnInit {
  @ViewChild('container', {read: ViewContainerRef}) container;
  timeTrackingFactory: ComponentFactory<TimeTrackingComponent>;
  locationFactory: ComponentFactory<LocationComponent>;
  private id: string;
  private sub: any;
  private components: IComponent[];
  selector = 'app-time-tracking';
  public dynamicHtml: SafeHtml;
  trustedHtml: SafeHtml;

  constructor(private route: ActivatedRoute, private dataService: DataService, private resolver: ComponentFactoryResolver, private navTitleService: NavTitleService) {
    this.timeTrackingFactory = this.resolver.resolveComponentFactory(TimeTrackingComponent);
    this.locationFactory = this.resolver.resolveComponentFactory(LocationComponent);
   }
  ngAfterViewInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.navTitleService.setNavTitle('JobXY');
    this.container.createComponent(this.locationFactory);
    this.container.createComponent(this.timeTrackingFactory);
  }

  ngOnInit(): void {

  }

}
