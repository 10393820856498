import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {JobListComponent} from './specific/components/job-list/job-list.component';
import {ReportComponent} from './specific/components/report/report.component';
import {TemplatesComponent} from './specific/components/templates/templates.component';
import {CustomersComponent} from './specific/components/customers/customers.component';
import {JobComponent} from './specific/components/job/job.component';
import {AddJobComponent} from './specific/components/add-job/add-job.component';



export const routes: Routes = [
  {
    path: '',
    redirectTo: 'jobs',
    pathMatch: 'full'
  },
  {
    path: 'jobs',
    component: JobListComponent
  },
  {
    path: 'job-details/:id',
    component: JobComponent
  },
  {
    path: 'job-add',
    component: AddJobComponent
  },
  {
    path: 'dailyReport',
    component: ReportComponent
  },
  {
    path: 'templates',
    component: TemplatesComponent
  },
  {
    path: 'customers',
    component: CustomersComponent
  },
];

@NgModule({
  exports: [RouterModule]
})
export class AppRoutingModule { }
