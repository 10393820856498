import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {NavTitleService} from '../../../services/nav-title-service/nav-title.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<void>();
  public navTitle: string;
  constructor(private navTitleService: NavTitleService) {
    this.navTitle = '';
  }

  ngOnInit(): void {
    this.navTitleService.getTitle().subscribe( appTitle => this.navTitle = appTitle);
  }

}
