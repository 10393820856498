import { Component, OnInit } from '@angular/core';
import {ITimeTrackingComponent} from '../../../../models/time-tracking-component';
import {Guid} from 'guid-typescript';
import {DataService} from '../../../../services/data-service/data.service';
import {ActivatedRoute} from '@angular/router';
import {Timestamp} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss']
})
export class TimeTrackingComponent implements OnInit {

  public startIcon = 'play_arrow';
  public stopIcon = 'stop';
  public actualIcon = '';
  private sub: any;
  private id: string;
  private startTime: Date;
  private endTime: Date;
  public durationMin: any = '00';
  public durationH: any = '00';
  public timeTrackingComponent: ITimeTrackingComponent;
  private now;
  private tackingIsActive = false;

  constructor(private dataService: DataService, private route: ActivatedRoute) {
    this.now = new Date().toString().split(' ')[4];
    console.log(this.now);
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.timeTrackingComponent = this.dataService.getTimeTrackingComponentById(this.id);
    if (this.tackingIsActive){
      this.actualIcon = this.stopIcon;
    }else{
      this.actualIcon = this.startIcon;
    }
  }

  toggleTracking(): void{
    if (!this.tackingIsActive){
      this.actualIcon = this.stopIcon;
      this.tackingIsActive = true;
      this.startTime = new Date();
      const stringTime = this.startTime.toString().split(' ')[4];
      this.timeTrackingComponent.startTime = stringTime.substr(0, stringTime.length - 3);
    }else{
      this.actualIcon = this.startIcon;
      this.tackingIsActive = false;
      this.endTime = new Date();
      const stringTime = this.endTime.toString().split(' ')[4];
      if (this.endTime.getMinutes() - this.startTime.getMinutes() > 9){
        this.durationMin = this.endTime.getMinutes() - this.startTime.getMinutes();
      }else{
        this.durationMin = '0' + (this.endTime.getMinutes() - this.startTime.getMinutes()).toString();
      }
      if (this.endTime.getHours() - this.startTime.getHours() > 9){
        this.durationH = this.endTime.getHours() - this.startTime.getHours();
      }else{
        this.durationH = '0' + (this.endTime.getHours() - this.startTime.getHours()).toString();
      }
      this.timeTrackingComponent.endTime = stringTime.substr(0, stringTime.length - 3);
    }
  }

}
