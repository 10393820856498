import { Injectable } from '@angular/core';
import { JobComponent } from 'src/app/models/job-component-model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DBConfig } from 'ngx-indexed-db';

@Injectable()
export class IdbService {

  dbConfig: DBConfig = {
    name: 'wpit-data',
    version: 1,
    objectStoresMeta: [{
      store: 'jobComponents',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'jobId', keypath: 'jobId', options: { unique: true } },
        { name: 'jobTitle', keypath: 'jobTitle', options: { unique: false }},
        { name: 'jobDescription', keypath: 'jobDescription', options: { unique: false }} 
      ]
    }]
  };

  

  constructor(private indexDb: NgxIndexedDBService<any>) {
    console.log(this.indexDb);
  }
  
  addJobComponent(component: JobComponent): void{
    console.log("addJobToDb");
    console.log(this.indexDb.count('jobComponents'));
    this.indexDb.add('jobComponents', {
      jobId: component.id,
      jobTitle: component.description,
      jobDescription: component.description
    }).subscribe((key) => {
      console.log('key: ', key);
    });
  }

  getAllJobComponents(): JobComponent[]{
    this.indexDb.getAll('jobComponents').subscribe((jobComponents)=> {
      return jobComponents;
    });
    return null;
  }

}
