<mat-card>
  <mat-card-content>
    <table  mat-table style="width: 100%">
      <tr>
        <td>
          <p>{{locationComponent.address}}</p>
          <p>{{locationComponent.zipCode}} {{locationComponent.address}}</p>
        </td>
        <td>
          <a href="{{googleMapsLink}}" style="color: black">
            <button mat-icon-button>
              <mat-icon>map</mat-icon>
            </button>
          </a>
        </td>
      </tr>
    </table>

  </mat-card-content>
</mat-card>
<p></p>
