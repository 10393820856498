import { Injectable } from '@angular/core';
import { JobComponent } from 'src/app/models/job-component-model';
import { IdbService } from '../index-db-service/idb.service';
import { IEventConsumer } from '../event-consumer/EventConsumer';
import {IEvent} from '../event-publisher/events/event';
import { AddJobEvent } from '../event-publisher/events/add-job-event';
import { EventPublisherService } from '../event-publisher/event-publisher.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {ILocationComponent} from '../../models/location-component';
import {Guid} from 'guid-typescript';
import {ITimeTrackingComponent} from '../../models/time-tracking-component';

@Injectable()
export class DataService {

  constructor(private dbService: NgxIndexedDBService<any>, private idbService: IdbService) {
    EventPublisherService.register(new AddJobEvent().eventName, this.addJobComponent);
    console.log(this.dbService);
    console.log(this.idbService);
  }

  addJobComponent(addJobEvent: AddJobEvent){
    //ToDo if backend connection is esablised
    console.log("addJobComponent consumer");
    var jobComponent:JobComponent = {id: addJobEvent.jobId, title: addJobEvent.jobTitle, description: addJobEvent.jobDescription}
    console.log(this);
    if (this.dbService !== undefined){
      console.log("in the if that means service is not undefined");
      this.addJobComponentToDb(jobComponent);
    }else{
      console.warn("no index db service available");
    }
  }

  getAllJobComponentsByCompanyId(companyId: string): JobComponent[]{
    return [
      {id: Guid.create().toString(), title: 'JobTitle1', description: 'jobDescription1'},
      {id: Guid.create().toString(), title: 'JobTitle2', description: 'jobDescription1'},
      {id: Guid.create().toString(), title: 'JobTitle3', description: 'jobDescription1'},
      {id: Guid.create().toString(), title: 'JobTitle4', description: 'jobDescription1'}
    ];
  }

  getComponentsOfJobById(id: string){

  }

  getLocationComponentById(id: string): ILocationComponent{
    return {id: Guid.create().toString(), address: 'Marktstraße 67', zipCode: '6850', place: 'Dornbirn'};
  }

  getTimeTrackingComponentById(id: string): ITimeTrackingComponent{
    return {id: Guid.create().toString(), startTime: '--:--', endTime: '--:--', title: 'Schnee räumen und salzen'};
  }

  private addJobComponentToDb(component: JobComponent): void{
    console.log("addJobToDb");
    console.log(this.dbService.count('jobComponents'));
    this.dbService.add('jobComponents', {
      jobId: component.id,
      jobTitle: component.description,
      jobDescription: component.description
    }).subscribe((key) => {
      console.log('key: ', key);
    });
  }

  getJobById(id: string) {

  }
}
