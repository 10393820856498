import { Component, OnInit } from '@angular/core';
import {NavTitleService} from '../../../services/nav-title-service/nav-title.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  constructor( private navTitleService: NavTitleService) { }

  ngOnInit(): void {
    this.navTitleService.setNavTitle('Templates');
  }

}
