import { Injectable } from '@angular/core';
import {IEvent} from './events/event';
import {IEventConsumer} from '../event-consumer/EventConsumer';

@Injectable({
  providedIn: 'root'
})
export class EventPublisherService {

  private static eventMap: Map<string, Function[]> = new Map<string, Function[]>();

  static register(event: string, consumer: Function){
    if (!this.eventMap.has(event))
    {
      const consumers:Function[] = [];
      consumers.push(consumer);
      this.eventMap.set(event, consumers);
    }else{
      this.eventMap.get(event).push(consumer);
    }
  }

  static publishEvent(event:IEvent){
    var consumers = this.eventMap.get(event.eventName);
    if(consumers != undefined){
      consumers.forEach((subscriber) => {
        subscriber(event);
    });
    }else{
      console.warn("No event subscriber for: " + event.eventName);
    }
    
  }
}
