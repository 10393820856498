<mat-accordion>
  <mat-expansion-panel *ngFor="let job of jobComponents; trackBy:trackByFn" class="expansionMargin">
    <mat-expansion-panel-header>
      {{job.title}}
    </mat-expansion-panel-header>
    {{job.description}}
    <mat-action-row>
      <button mat-icon-button color="black" [routerLink]="['/job-details/', job.id]">
        <mat-icon>open_in_browser</mat-icon>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
<button mat-fab color="primary" class="fixed-right-bottom-button" [routerLink]="['/job-add']">
  <mat-icon>add</mat-icon>
</button>
