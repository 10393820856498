import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './core/components/nav/nav.component';
import { NavBarComponent } from './core/components/nav-bar/nav-bar.component';
import { JobListComponent } from './specific/components/job-list/job-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import { ReportComponent } from './specific/components/report/report.component';
import { TemplatesComponent } from './specific/components/templates/templates.component';
import { CustomersComponent } from './specific/components/customers/customers.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { JobComponent } from './specific/components/job/job.component';
import { AddJobComponent } from './specific/components/add-job/add-job.component';
import {MatInputModule} from '@angular/material/input';
import {RouterModule, Routes} from '@angular/router';
import { routes } from './app-routing.module';
import {EventPublisherService} from './services/event-publisher/event-publisher.service';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { IdbService } from './services/index-db-service/idb.service';
import { DataService } from './services/data-service/data.service';
import { TimeTrackingComponent } from './specific/components/documentationComponents/time-tracking/time-tracking.component';
import { LocationComponent } from './specific/components/documentationComponents/location/location.component';
import { SetDocuComponentDirective } from './directives/docuComponent/set-docu-component.directive';
import {MatCardModule} from '@angular/material/card';

const dbConfig: DBConfig = {
  name: 'wpit-data',
    version: 1,
    objectStoresMeta: [{
      store: 'jobComponents',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'jobId', keypath: 'jobId', options: { unique: true } },
        { name: 'jobTitle', keypath: 'jobTitle', options: { unique: false }},
        { name: 'jobDescription', keypath: 'jobDescription', options: { unique: false }}      ]
    }]
};

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NavBarComponent,
    JobListComponent,
    ReportComponent,
    TemplatesComponent,
    CustomersComponent,
    JobComponent,
    AddJobComponent,
    TimeTrackingComponent,
    LocationComponent,
    SetDocuComponentDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatExpansionModule,
    MatInputModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    MatCardModule
  ],
  providers: [EventPublisherService, DataService, IdbService, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
