<mat-card>
  <mat-card-content>
    <h3><b>{{timeTrackingComponent.title}}</b></h3>
    <table mat-table style="width: 100%">
      <tr>
        <th mat-header-cell>Start</th>
        <th mat-header-cell>Dauer</th>
        <th mat-header-cell>Ende</th>
        <th mat-header-cell></th>
      </tr>
      <tr>
        <td mat-cell>{{timeTrackingComponent.startTime}}</td>
        <td mat-cell>{{durationH}}:{{durationMin}}</td>
        <td mat-cell>{{timeTrackingComponent.endTime}}</td>
        <td mat-cell >
          <button mat-icon-button color="black" (click)="toggleTracking()">
            <mat-icon>{{actualIcon}}</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
<p></p>
