import {IEvent} from './event';
import {Guid} from 'guid-typescript';

export class AddJobEvent implements IEvent{
  public eventName: string;
  public jobId: string;
  constructor(public jobTitle?: string, public jobDescription?: string) {
    this.eventName = 'AddJobEvent';
    this.jobId = Guid.create().toString();
  }
}
