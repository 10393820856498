import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[setDocuComponent]'
})
export class SetDocuComponentDirective {

  private _tag: string;
  private _needUpdate: boolean = false;

  constructor(private templateRef: TemplateRef<any>, private vcf: ViewContainerRef) { }

  @Input('setDocuComponent')
  set tag(tag: string){
    console.log(tag);
    this._tag = tag;
    this._needUpdate = true;
    this.vcf.clear();
    let template = this.templateRef.elementRef.nativeElement.nextElementSibling;
    if(template){
      this.templateRef.elementRef.nativeElement.parentNode.removeChild(template)
    }
    this.vcf.createEmbeddedView(this.templateRef);
  }

  ngAfterViewChecked() {
    if (this._needUpdate) {
      this._updateTemplate();
      this._needUpdate = false;
    }
  }

  private _updateTemplate() {
    console.log(this.templateRef);
    let template = this.templateRef.elementRef.nativeElement.nextElementSibling;
    if (template) {
      let r = document.createElement(this._tag);
      r.innerHTML = template.innerHTML;
      this.templateRef.elementRef.nativeElement.parentNode.replaceChild(r, template);
    }
  }
}
